<script>
export default {
  data(){
    return{
      tableData: null,
      pageSize: 20,
      pageNum: 1,
      total: 0,
    }
  },
  created() {
    this.getTableList()
  },
  computed:{
    carBrand(){
      return this.tableData.map(item => item.carBrand)
    },
    free(){
      return this.tableData.map(item => item.free)
    },
    possess(){
      return this.tableData.map(item => {
        return item.possess === "0"? "" : item.possess
      })
    }
  },
  methods: {
    /**
     * 获取数据列表
     * @param data {Object} 查询条件
     * @param mod {boolean} 是否有提示
     */
    getTableList(data = null, mod = false){
      this.axios.get(this.$api.publicUrl.CarStat, {
        params: {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          ...data
        }
      }).then(res =>{
        if(res.data.status === 200){
          this.tableData = res.data.data
          this.getEchartData()
          if(mod){
            this.$message.success("查询成功！")
          }
        }else {
          this.$message(res.data.data.message)
        }
      })
    },

    getEchartData(){
      const chart = this.$refs.chart
      if (chart) {
        const myChart = this.$echarts.init(chart)
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            textStyle: {
              fontSize: 20
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            axisLine:{
              lineStyle: {
                // color: "#ffffff",

              },
              textStyle: {
                fontSize: 20
              }
            }
          },
          yAxis: {
            type: 'category',
            data: this.carBrand,
            textStyle: {
              fontSize: 20
            }
          },
          series: [
            {
              name: '占用',
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
                "textStyle": {
                  "fontSize": 18,
                  color: "#fff"
                }

              },
              emphasis: {
                focus: 'series'
              },
              data: this.possess,
              itemStyle: {
                color: "#e6a23c"
              },

            },
            {
              name: '空闲',
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
                "textStyle": {
                  "fontSize": 18,
                  color: "#fff"
                }
              },
              emphasis: {
                focus: 'series'
              },
              data: this.free,
              itemStyle: {
                color: "#4cce0b"
              }
            },
          ],
        }
        myChart.setOption(option)
        window.addEventListener("resize", function() {
          myChart.resize()
        })
      }
      this.$on('hook:destroyed',()=>{
        window.removeEventListener("resize", function() {
          myChart.resize();
        });
      })
    },


    handleSizeChange(val) {
      this.pageSize = val
      this.getTableList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getTableList()
    }
  }
}
</script>

<template>
  <fragment>
    <section class="tabulation" ref="chart">
    </section>
  </fragment>

</template>
<style src="@/assets/css/componentStyle.css" scoped></style>
<style scoped>
th{
  text-align: center;
}
tr{

}
</style>
